import React from "react";

export default function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      viewBox="0 0 305 90"
      className="flight-logo"
      width="140"
      height="40"
    >
      <defs>
        <path
          id="reuse-0"
          fill="currentColor"
          d="M35 33.7a8.7 8.7 0 1 0 0-17.4 8.7 8.7 0 1 0 0 17.4ZM35 53.8a8.7 8.7 0 1 0 0-17.4 8.7 8.7 0 0 0-8.8 8.7c0 4.7 4 8.7 8.8 8.7ZM55 53.8a8.7 8.7 0 1 0 0-17.4 8.7 8.7 0 0 0-8.8 8.7c0 4.7 4 8.7 8.8 8.7ZM35 73.7a8.7 8.7 0 1 0 0-17.4 8.7 8.7 0 1 0 0 17.4ZM55.2 33.7a8.7 8.7 0 1 0 0-17.4 8.7 8.7 0 1 0 0 17.4Z"
        />
      </defs>
      <path
        fill="currentColor"
        d="M113.2 40h27v9h-27v26.6H103V13h43.6v8.8h-33.4V40ZM153 13h9.1v62.4H153V13Zm22.6 10.3c-2.6 0-5.1-1.7-5.1-5 0-3.2 2.5-4.7 5.1-4.7 2.8 0 5.1 1.8 5.1 4.8 0 3.2-2.5 4.9-5.1 4.9Zm-4.5 7.7h9.2v44.6h-9.2V31Zm49.1-1h9.2v41.6c0 12.8-7.7 18.4-20.3 18.4-12.8 0-19.8-6.6-20.7-14.2h9.2c.8 3.9 4 7 11.3 7 7.7 0 11.5-3.5 11.5-11.7v-5.3h-.2a15.8 15.8 0 0 1-13.4 6.6c-11.1 0-19.5-7.7-19.5-21.9 0-14.1 8.4-21.8 19.5-21.8 7 0 11 3.2 13.4 6.4h.2v-5.2h-.2Zm-12 35.8c7.5 0 11.8-5 11.8-14.4S215.5 37 208.3 37c-8 0-12.2 6-12.2 14.4.2 8.4 4.3 14.4 12.2 14.4Zm53.5-36c8.3 0 14.9 4.6 14.9 15v30.8h-9.2V47.3c0-6-2.5-9.8-9-9.8-6.6 0-11.5 4.2-11.5 10.9v27.2h-9.2V13h9.2v23.6h.2c2.6-3.4 7-6.9 14.6-6.9Zm43.3 8.7h-8.3v24.3c0 5.1 2.3 5.5 8.3 5.3v7.5c-2 .5-3.8.7-6.2.7-7.3 0-11.3-3.2-11.3-12.7v-25H281V31h6.4V17h9.2V31h8.3v7.5ZM45 7.6a37.3 37.3 0 1 1 0 74.6 37.3 37.3 0 0 1 0-74.6ZM45 0a45 45 0 1 0 0 90 45 45 0 0 0 0-90Z"
      />
      <path
        fill="currentColor"
        d="M45 7.6a37.3 37.3 0 1 1 0 74.6 37.3 37.3 0 0 1 0-74.6ZM45 0a45 45 0 1 0 0 90 45 45 0 0 0 0-90Z"
      />
      <use xlinkHref="#reuse-0" />
      <use xlinkHref="#reuse-0" />
    </svg>
  );
}
