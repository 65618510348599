import { colors, colorsRGB } from "@util/constants";
import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";

type animation =
  | "zoom"
  | "fade"
  | "flip"
  | "door"
  | "rotate"
  | "slideUp"
  | "slideDown"
  | "slideLeft"
  | "slideRight";

interface Props {
  children: JSX.Element;
  onClose: () => void;
  onAnimationEnd?: () => void;
  visible: boolean;
  width?: string;
  height?: string;
  animationType?: animation;
}

const Modal = (props: Props) => {
  const {
    children,
    width,
    height,
    visible,
    animationType,
    onClose,
    onAnimationEnd,
  } = props;

  return (
    <Rodal
      visible={visible}
      onClose={onClose}
      animation={animationType}
      onAnimationEnd={onAnimationEnd}
      customMaskStyles={{ background: colorsRGB.black(0.8) }}
      customStyles={{
        width: width ?? "70%",
        height: height ?? "80%",
        minHeight: "35vh",
        overflow: "auto",
      }}
    >
      {children}
    </Rodal>
  );
};

export default Modal;
