import React, { createElement } from "react";
import styled from "styled-components";
import PortableText from "@sanity/block-content-to-react";

import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  TWENTY_INCH_SCREEN,
  colors,
} from "@util/constants";
import { Maybe, Scalars } from "@graphql-types";
import { urlForImageRef } from "@util/sanity/client";
import { Container } from "@util/standard";
import Button from "./button";

interface Props {
  blocks: Maybe<Scalars["JSON"]>;
  textStyle?: string;
  imgStyle?: string;
  mobileTextStyle?: string;
  mobileImgStyle?: string;
  isH1?: boolean;
  maxWidth?: Maybe<string> | undefined;
}

const BlocksContent = ({ blocks, maxWidth, isH1, ...rest }: Props) => {
  return (
    <StyledPortableText
      blocks={blocks}
      className="blocks-content"
      serializers={{
        types: {
          link: (props: any) => {
            if (props.node.newwindow) {
              return (
                <Button
                  theme="base"
                  text={props.node.linktext}
                  linkToNewWindow={props.node.url}
                />
              );
            }
            return (
              <Button
                theme="base"
                text={props.node.linktext}
                linkTo={`/${props.node.url}/`}
              />
            );
          },
          image: (props: any) => {
            return (
              <Img
                url={urlForImageRef(props.node.asset).url() as string}
                style={{ margin: "0" }}
              />
            );
          },
          block: (props: any) => {
            const { style = "normal" } = props.node;
            const tag = style;

            if (/^h\d/.test(tag)) {
              const level = tag.replace(/[^\d]/g, "");
              if (level === "1" && !isH1) {
                return createElement(
                  "span",
                  {
                    className: `heading-${level}`,
                  },
                  props.children
                );
              }

              return createElement(
                tag,
                {
                  className: `heading-${level}`,
                },
                props.children
              );
            }

            if (style === "blockquote") {
              return <blockquote>{props.children}</blockquote>;
            }

            return <p>{props.children}</p>;
          },
        },
        marks: {
          highlight: (props: any) => {
            if (!props) return null;

            return <span className="text-tag">{props.children}</span>;
          },
          subHeading: (props: any) => {
            return (
              <span className="sub-heading" style={{ fontSize: 20 }}>
                {props.children}
              </span>
            );
          },
          bigBoi: (props: any) => {
            if (!props) return null;
            return <span className="big-boi">{props.children}</span>;
          },
        },
      }}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset="development"
      maxWidth={maxWidth}
      {...rest}
    />
  );
};

export default BlocksContent;

const Img = styled(Container)<{ url?: string }>`
  margin: 20px 0px 35px 0px;
  height: 800px;
  width: 100%;
  ${({ url }) => url && `background-image: url(${url})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: ${TWENTY_INCH_SCREEN}px) {
    height: 700px;
  }

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    height: 600px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 500px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
    margin: 20px 0px;
  }
`;

const StyledPortableText = styled(PortableText)<{
  textStyle?: string;
  imgStyle?: string;
  mobileTextStyle?: string;
  mobileImgStyle?: string;
  maxWidth?: Maybe<string>;
}>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "100%"};

  p {
    margin: 10px auto 30px auto;
    direction: ltr;
  }

  a {
    color: #fff;
  }

  strong {
    font-size: inherit;
  }

  .heading-1 {
    font-size: 100px;
    line-height: 100px;
    font-weight: 400;
  }

  h2 {
    font-size: 50px;
    font-weight: 400;
    margin-top: 0;
  }

  li {
    margin-bottom: 20px;
    line-height: 21px;
    color: inherit;
  }
  .text-tag {
    display: block;
    width: max-content;
    margin: auto;
    text-align: center;
    background: ${colors.flightBlue};
    color: ${colors.white};
    padding: 10px 15px;
  }
  ${({ imgStyle }) => imgStyle && `div {${imgStyle}}`};

  .sub-heading {
    font-size: 20px;
  }

  .big-boi {
    font-size: 120px;
    line-height: 120px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 90px;
      line-height: 90px;
      max-width: 90%;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 90%;
  }
`;
