import React, { ReactNode } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

import { TextButton } from "@util/standard";
import { Maybe } from "@graphql-types";
import { Color } from "@util/types";
import { colors } from "@util/constants";

const StyledLink = styled(GatsbyLink)<{ color?: Color }>`
  text-decoration: none;
  ${({ color }) => color && `color: ${colors[color]};`}

  .icon-wrapper {
    position: relative;
    overflow: hidden;
  }
`;

interface Props {
  pathTo: Maybe<string> | undefined;
  children: ReactNode;
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  margin?: string;
  fontSize?: number;
  bold?: boolean;
  hover?: boolean;
}

const LinkButton = (props: Props) => {
  return (
    <StyledLink to={props.pathTo ?? "/"} color={props.color}>
      <TextButton {...props}>{props.children}</TextButton>
      {/* <span className="icon-wrapper">
        <Icon viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
          ></path>
        </Icon>
        <Icon viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
          ></path>
        </Icon>
      </span> */}
    </StyledLink>
  );
};

const Icon = styled.svg`
  width: 24px;
  height: 24px;
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
`;

export default LinkButton;
