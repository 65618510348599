import React from "react";

import { SanityTextCta } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent, Button } from "@sub";
import styled from "styled-components";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityTextCta;
}

const Wrapper = styled(Container)`
  width: 86.5%;

  margin: 0 auto 150px auto;
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto 25px auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    width: 94%;
  }
`;

const TextWrapper = styled(Container)`
  width: 60%;
  margin: 0;
  h2 {
    font-size: 40px;
    margin: 0px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

function TextCta({ data }: Props) {
  if (data == null) return null;

  const { textContent, cta } = data;

  return (
    <Wrapper>
      {textContent && (
        <TextWrapper>
          <BlocksContent blocks={data?.textContent?._rawColumnContent} />
        </TextWrapper>
      )}
      {cta && cta.url && (
        <Container
          width="max-content"
          margin="0"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Button
            width="max-content"
            text={cta.linktext as string}
            linkTo={cta.url}
          />
        </Container>
      )}
    </Wrapper>
  );
}

export default TextCta;
