import React from "react";
import styled from "styled-components";

import { Dimensions, Color } from "@util/types";
import {
  colorsRGB,
  colors,
  BUTTON_HEIGHT,
  MOBILE_BREAKPOINT,
} from "@util/constants";

const StyledInput = styled.input<{
  dimensions?: Dimensions;
  margin?: string;
  fontSize?: number;
  mobileMargin?: string;
  hidden?: boolean;
  inputColor?: Color;
  hasInput?: boolean;
}>`
  display: flex;
  flex: 1;
  background-color: transparent;
  color: ${(props) =>
    props.inputColor ? props.inputColor : `${colors.flightBlue}`};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ mobileMargin }) =>
    mobileMargin &&
    ` @media only screen and (max-width: ${MOBILE_BREAKPOINT}px){
    margin: ${mobileMargin};
  }`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px; `}
  ${({ dimensions }) => (dimensions ? `width: ${dimensions.width};` : "100%;")}
  ${({ hidden }) => hidden && `display: none;`}
  height: ${(props) => props.dimensions?.height ?? `${BUTTON_HEIGHT}px`};
  border: 1px solid
    ${(props) =>
      props.inputColor
        ? colors[props.inputColor]
        : props.hasInput
        ? colors.flightBlue
        : colorsRGB.flightBlue(0.4)};
  padding-left: 20px;
  font-family: "NeueMontreal-Regular";
  &:focus {
    outline: 0;
    border: 1px solid ${colors.flightBlue};
  }

  ::placeholder {
    color: ${colorsRGB.flightBlue(0.6)};
  }
  &::-webkit-input-placeholder {
    font-family: "NeueMontreal-Regular";
  }
`;

const StyledTextArea = styled.textarea<{
  dimensions?: Dimensions;
  hidden?: boolean;
  hasInput?: boolean;
}>`
  display: flex;
  flex: 1;
  padding: 10px;
  ${({ dimensions }) => (dimensions ? `width: ${dimensions.width};` : "100%;")}
  ${({ hidden }) => hidden && `display: none;`}
  height: ${(props) => props.dimensions?.height ?? `${BUTTON_HEIGHT}px`};
  border: 1px solid ${colors.flightBlue};
  font-family: "NeueMontreal-Regular";
  padding-left: 20px;
  padding-right: 20px;
  color: ${colors.flightBlue};
  ::placeholder {
    color: ${colorsRGB.flightBlue(0.6)};
  }
  &:focus {
    outline: 0;
    border: 1px solid ${colors.flightBlue};
  }
  &::-webkit-input-placeholder {
    font-family: "NeueMontreal-Regular";
  }
`;

interface Props {
  dimensions?: Dimensions;
  mobileMargin?: string;
  margin?: string;
  fontSize?: number;
  placeholderText?: string;
  name?: string;
  onChange?: (arg?: any) => void;
  value?: string;
  isTextArea?: boolean;
  type?: string;
  required?: boolean;
  hidden?: boolean;
  inputColor?: Color;
}

const TextInput = (props: Props) => {
  if (props.isTextArea) {
    return (
      <StyledTextArea
        name={props.name}
        placeholder={props.placeholderText}
        onChange={props.onChange}
        value={props.value}
        dimensions={props.dimensions}
        hidden={props.hidden}
        hasInput={props.value !== undefined && props.value.length > 0}
      />
    );
  }

  return (
    <StyledInput
      required={props.required}
      name={props.name}
      fontSize={props.fontSize}
      margin={props.margin}
      mobileMargin={props.mobileMargin}
      placeholder={props.placeholderText}
      onChange={props.onChange}
      value={props.value}
      dimensions={props.dimensions}
      type={props.type}
      hidden={props.hidden}
      inputColor={props.inputColor}
      hasInput={props.value !== undefined && props.value.length > 0}
    />
  );
};

export default TextInput;
