import styled from "styled-components";
import { colors, colorsRGB, fontSizes } from "./constants";
import {
  AlignItems,
  AlignSelf,
  Dimensions,
  FlexDirection,
  JustifyContent,
  Overflow,
  PDisplayType,
  FlexWrap,
  Cursor,
  WhiteSpace,
  Color,
  Display,
} from "./types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "@util/constants";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: ${(props) => props.zIndex ?? 3};
  left: 0;
  top: 0;
  background-color: ${colorsRGB.black(0.5)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  noMargin?: boolean;
  margin?: string;
  width?: string;
  underlined?: boolean;
  fontWeight?: number;
  opacity?: number;
  isMobileStyling?: boolean;
  display?: PDisplayType;
  whiteSpace?: WhiteSpace;
  cursor?: Cursor;
  padding?: number;
  textAlign?: string;
  bold?: boolean;
  lineHeight?: string;
}>`
  color: ${(props) => (props.color ? colors[props.color] : colors.flightBlue)};
  margin: ${(props) => (props.margin ? props.margin : `10px 0px`)};
  font-size: ${(props) => props.fontSize ?? fontSizes.p.default}px;

  line-height: ${(props) =>
    props.fontSize ? props.fontSize * 1.5 : fontSizes.p.default * 1.5}px;

  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  background-color: transparent;
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ noMargin }) => noMargin && `margin: 0px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ padding }) => padding && `padding: ${padding}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}

  ${({ isMobileStyling }) =>
    isMobileStyling &&
    `@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      width: 80%;
      text-align: center;
      margin: auto;
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.p.mobile}px;
    line-height: ${fontSizes.p.mobile * 1.5}px;
  }
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  hoverColor?: Color;
  margin?: string;
  bold?: boolean;
}>`
  color: ${(props) => (props.color ? colors[props.color] : colors.flightBlue)};
  text-decoration: ${(props) => (props.underlined ? "underline" : "none")};
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const H1 = styled.h1<{
  noMargin?: boolean;
  fontWeight?: string;
  fontSize?: number;
  margin?: string;
  mobileFontSize?: number;
  bold?: boolean;
}>`
  color: ${colors.flightBlue};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : fontSizes.h1.default}px;
  margin: ${(props) => (props.margin ? props.margin : `15px 0px`)};

  ${({ noMargin }) => noMargin && `margin: 0px;`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(props) =>
      props.mobileFontSize ? props.mobileFontSize : fontSizes.h1.mobile}px;
    line-height: ${(props) =>
      props.mobileFontSize
        ? props.mobileFontSize * 1.5
        : fontSizes.h1.mobile * 1.5}px;
  }
`;

export const H2 = styled.h2<{
  width?: number;
  fontSize?: number;
  color?: Color;
  underlined?: boolean;
  cursor?: Cursor;
  margin?: string;
  noMargin?: boolean;
  bold?: boolean;
}>`
  color: ${(props) => (props.color ? colors[props.color] : colors.flightBlue)};
  margin: ${(props) => (props.margin ? props.margin : `15px 0px`)};
  white-space: initial;
  ${({ noMargin }) => noMargin && `margin: 0px;`}

  font-size: ${(props) => props.fontSize ?? fontSizes.h2.default}px;
  line-height: ${(props) => props.fontSize ?? fontSizes.h2.default}px;
  ${({ width }) => width && `width: ${width}px;`}
  ${({ underlined }) => underlined && `text-decoration: underline;`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 34px;
    line-height: ${fontSizes.h2.mobile * 1.5}px;
  }
`;

export const H3 = styled.h3<{
  fontSize?: number;
  color?: Color;
  underlined?: boolean;
  margin?: string;
  noMargin?: boolean;
  mobileFontSize?: number;
  textAlign?: string;
  bold?: boolean;
}>`
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ underlined }) => underlined && `text-decoration: underline;`}
  color: ${(props) => (props.color ? colors[props.color] : colors.flightBlue)};
  font-size: ${(props) => props.fontSize ?? fontSizes.h3.default}px;
  line-height: ${(props) =>
    props.fontSize ? props.fontSize : fontSizes.h3.default}px;

  margin: ${(props) => (props.margin ? props.margin : `15px 0px`)};
  ${({ noMargin }) => noMargin && `margin: 0px;`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(props) =>
      props.mobileFontSize ? props.mobileFontSize : fontSizes.h3.mobile}px;
    line-height: ${(props) =>
      props.mobileFontSize
        ? props.mobileFontSize * 1.5
        : fontSizes.h3.mobile * 1.5}px;
  }
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  dimensions?: Dimensions;
  opacity?: number;
  shouldShowMobile?: boolean;
  fullHeight?: boolean;
}>`
  border-left: 1px solid
    ${(props) => (props.color ? colors[props.color] : colors.flightBlue)};
  height: ${(props) =>
    props.dimensions?.height ?? props.fullHeight ? undefined : `85px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldShowMobile }) =>
      shouldShowMobile ? "display: block;" : "display: none;"}
  }
`;

export const Separator = styled.div<{
  color?: Color;
  dimensions?: Dimensions;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  marginBottom?: number;
  width?: string;
}>`
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}px;` : `margin-bottom: 10px`};
  border-bottom: ${(props) => props.thickness ?? 2}px solid
    ${(props) => (props.color ? colors[props.color] : colors.flightBlue)};
  ${({ dimensions }) =>
    dimensions && dimensions.width && `width: ${dimensions.width ?? "100px"};`}
  height: ${(props) => props.dimensions?.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ width }) => width && `width: ${width};`}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: FlexDirection;
  flex?: number | string;
  flexGrow?: number;
  flexWrap?: FlexWrap;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignSelf?: AlignSelf;
  mobileAlignSelf?: AlignSelf;
  borderRadius?: number;
  overflow?: Overflow;
  padding?: string;
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  mobilePadding?: string;
  mobileJustifyContent?: JustifyContent;
  tabletWidth?: string;
  mobileWidth?: string;
  mobileMargin?: string;
  tabletMargin?: string;
  mobileDimensions?: Dimensions;
  tabletDimensions?: Dimensions;
  width?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  cursor?: Cursor;
  reverseDirection?: boolean | null;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  autoWidthNoMargin?: boolean;
  zIndex?: number;
  display?: Display;
  position?: string;
  maxHeight?: string;
  rowGap?: string;
  maxWidth?: string;
  flexShrink?: number;
}>`
  display: ${(props) => (props.display ? props.display : "flex")};
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}

  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap};`}

  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ paddingVertical }) =>
    paddingVertical &&
    `padding: 0px ${paddingVertical}px 0px ${paddingVertical}px;`}
    ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `padding: ${paddingHorizontal}px 0px ${paddingHorizontal}px 0px;`}
  ${({ img }) => img && `background-image: url(${img});`}
  flex-direction: ${(props) => props.flexDirection ?? "initial"};
  justify-content: ${(props) => props.justifyContent ?? "initial"};
  align-items: ${(props) => props.alignItems ?? "initial"};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) =>
    backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  align-self: ${(props) => props.alignSelf ?? "initial"};
  overflow: ${(props) => props.overflow ?? "initial"};
  border-radius: ${(props) => props.borderRadius ?? 0}px;
  background-size: cover;
  width: ${(props) => (props.width ? props.width : "80%")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  ${({ autoWidthNoMargin }) => autoWidthNoMargin && `width: auto; margin: 0;`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}

  ${({ reverseDirection }) =>
    reverseDirection && `flex-direction: row-reverse;`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ flexShrink }) => flexShrink && `flex-shrink: ${flexShrink};`}


  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}
    ${({ mobileJustifyContent }) =>
      mobileJustifyContent && `justify-content: ${mobileJustifyContent};`}

    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding}`}
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
    ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.height &&
      `height: ${tabletDimensions.height};`}
    ${({ tabletDimensions }) =>
      tabletDimensions &&
      tabletDimensions.width &&
      `width: ${tabletDimensions.width};`}

      ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
      ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
      ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
    ${({ tabletMargin }) => tabletMargin && `margin: ${tabletMargin};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.height &&
      `height: ${mobileDimensions.height};`}
    ${({ mobileDimensions }) =>
      mobileDimensions &&
      mobileDimensions.width &&
      `width: ${mobileDimensions.width};`}

    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) =>
      mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`};
  }
`;

export const GridContainer = styled.div<{
  width?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  columnGap?: string;
  rowGap?: string;
  backgroundColor?: Color;
  position?: string;
  direction?: string;
  tabletDirection?: string;
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.repeat ?? 4}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap};`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap};`}
  ${({ direction }) => direction && `direction: ${direction};`};
  width: ${(props) => (props.width ? props.width : "100%")};
  margin: ${(props) => (props.margin ? props.margin : "0")};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.tabletRepeat ?? 2}, 1fr);
    ${({ tabletDirection }) =>
      tabletDirection && `direction: ${tabletDirection};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(${(props) => props.mobileRepeat ?? 1}, 1fr);
  }

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${colors[backgroundColor]};`};
  ${({ position }) => position && `position: ${position};`};
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  hover?: boolean;
}>`
  cursor: pointer;
  user-select: none;
  text-decoration-line: ${(props) => (props.underlined ? "underline" : "none")};
  color: ${(props) => (props.color ? colors[props.color] : colors.flightBlue)};
  width: fit-content;
  line-height: 100%;
  padding-bottom: 2px;

  background: linear-gradient(
      to right,
      rgba(100, 200, 200, 0),
      rgba(100, 200, 200, 0)
    ),
    linear-gradient(270deg, #0000ff 14.11%, #0000ff 65.01%), #transparent;
  background-size: 100% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

  &:hover {
    background-size: 0 1px, 100% 1px;
    cursor: url("/cursorClick.svg"), auto;
  }

  ${({ hover }) => hover && `background-size: 0 1px, 100% 1px;`};
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${(props) => props.space ?? 10}px;
`;

export const BlueBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 65vh;
  width: 100%;
  background-color: ${colors.flightBlue};
  z-index: 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const DesktopContainer = styled(Container)`
  display: block;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const TabletContainer = styled(Container)`
  display: block;

  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const MobileContainer = styled(Container)`
  display: block;

  @media only screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export const GradientBar = styled.div`
  width: 100%;
  height: 12px;
  background: linear-gradient(270deg, #8739e6 14.11%, #de6aeb 65.01%), #fff;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const standardUnderline = `  
line-height: 100%;
padding-bottom: 2px;

  background: linear-gradient(
      to right,
      rgba(100, 200, 200, 0),
      rgba(100, 200, 200, 0)
    ),
    linear-gradient(270deg, #0000ff 14.11%, #0000ff 65.01%), transparent;
  background-size: 100% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

  &:hover {
    background-size: 0 1px, 100% 1px;
    cursor: url("/cursorClick.svg"), auto;
  }
`;
