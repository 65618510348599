import React from "react";
import styled from "styled-components";

import { SanityColoredTextColumn } from "@graphql-types";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT, brandColors } from "@util/constants";
import { Container } from "@util/standard";
import { BrandColors } from "@util/types";
import { BlocksContent } from "@sub";

interface Props {
  data: SanityColoredTextColumn;
}

const Wrapper = styled(Container)`
  width: 100%;
  margin: 0 auto 120px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto 25px auto;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

function ColoredTextColumns({ data }: Props) {
  if (data == null) return null;

  const { columnOneText, columnOneBackground, columnTwoText, columnTwoBackground } = data;

  return (
    <Wrapper>
      {columnOneText && (
        <Container
          width="48%"
          margin="0 4% 0 0"
          mobileWidth="100%"
          mobileMargin={columnTwoText ? "0 0 25px 0" : "0"}
          backgroundRGBColor={brandColors[columnOneBackground as BrandColors] ?? undefined}
        >
          <Container width="70%" margin="50px auto">
            <BlocksContent blocks={columnOneText._rawColumnContent} />
          </Container>
        </Container>
      )}
      {columnTwoText && (
        <Container
          width="48%"
          margin="0 0% 0 0"
          mobileWidth="100%"
          mobileMargin="0 0 0px 0"
          backgroundRGBColor={brandColors[columnTwoBackground as BrandColors] ?? undefined}
        >
          <Container width="70%" margin="50px auto">
            <BlocksContent blocks={columnTwoText._rawColumnContent} />
          </Container>
        </Container>
      )}
    </Wrapper>
  );
}

export default ColoredTextColumns;
