import React, { ReactNode, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import { Helmet } from "react-helmet";

import "../../styles/globalStyles.css";
import Header from "./header";
import Footer from "./footer";
import {
  colors,
  DEFAULT_OFFSET,
  MOBILE_OFFSET,
  HEADER_HEIGHT,
  MOBILE_BREAKPOINT,
  flightAscii,
  fontSizes,
  defaultTheme,
} from "@util/constants";
import ThemeProvider from "./themeProvider";

interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    const isWorkTemplate = Boolean(
      (children as any)?.props?.data?.allSanityWork?.nodes?.[0]
    );

    if (!isWorkTemplate) {
      setTheme(defaultTheme);
    }
  }, [children]);

  return (
    <ThemeProvider value={{ theme, setTheme }}>
      <Helmet>
        <meta name="Site By Flight Digital" content={flightAscii} />
        {/* hubspot tracking code */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/5521628.js"
        />
      </Helmet>
      <GlobalStyle />
      <Header theme={theme} />
      <Main>{children}</Main>
      <Footer theme={theme} />
    </ThemeProvider>
  );
}

export default Layout;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
    font-family: "NeueMontreal-Regular", sans-serif;
    color: ${colors.flightBlue};
    cursor: url("/cursor.svg"), auto;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;

  button:hover {
      cursor: url("/cursorClick.svg"), auto;
    }

    a {

      color: ${colors.flightBlue};

      &:hover {
        cursor: url("/cursorClick.svg"), auto;
      }
    }
    h1,h2,h3,h4 {
      font-family: "NeueMontreal-Regular", sans-serif; 
    }
    .heading-1 {
      font-size: ${fontSizes.h1.default}px;
      line-height: 100%;

      @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        font-size: ${fontSizes.h1.mobile}px !important;
      line-height: ${fontSizes.h1.mobile * 1.5}px !important;
      }
    }
    .slick-slider, .swiper-container {
      cursor: url("/swipe.svg"), auto;
    }

    h1,
  .heading-1 {
    font-size: ${fontSizes.h1.default}px;
    line-height: 100%;
    font-weight: normal;
    direction: ltr;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h1.mobile}px;
      line-height: ${fontSizes.h1.mobile * 1.5}px;
    }
  }

  h2 {
    font-size: ${fontSizes.h2.default}px;
    line-height: ${fontSizes.h2.default * 1.5}px;

    direction: ltr;
    font-weight: normal;


    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h2.mobile}px;
      line-height: ${fontSizes.h2.mobile * 1.5}px;
    }
  }

  h3 {
    font-size: ${fontSizes.h3.default}px;
    line-height: ${fontSizes.h3.default * 1.5}px;
    font-weight: normal;
    direction: ltr;


    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.h3.mobile}px;
      line-height: ${fontSizes.h3.mobile * 1.5}px;
    }
  }

  h6 {
    font-size: ${fontSizes.p.default}px;
    line-height: ${fontSizes.p.default * 1.5}px;
    font-weight: normal;
    margin: 30px 0 10px 0;
    direction: ltr;


    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.p.mobile}px;
      line-height: ${fontSizes.p.mobile * 1.5}px;
    }
  }

  p, a, li {
    font-size: ${fontSizes.p.default}px;
    line-height: ${fontSizes.p.default * 1.5}px;
    

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: ${fontSizes.p.mobile}px;
      line-height: ${fontSizes.p.mobile * 1.5}px;
    }




  }
  }
`;

const Main = styled.main<{}>`
  // padding: ${HEADER_HEIGHT}px 20px ${DEFAULT_OFFSET}px 20px;
  // overflow: hidden;

  // @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  //   padding: ${HEADER_HEIGHT}px 20px ${MOBILE_OFFSET}px 20px;
  // }
`;
