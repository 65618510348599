import { SanityVideoTextColumn } from "@graphql-types";
import {
  ImageContainer,
  ImageTextBlockContainer,
  SecondColumnContainer,
} from "../../../templates/template.styles";
import React from "react";
import Video from "./video";
import BlocksContent from "./blocksContent";

interface Props {
  data: SanityVideoTextColumn;
}

const ImageVideoColumn = ({ data }: Props) => {
  if (data == null) return null;

  return (
    <ImageTextBlockContainer
      key={data?._key}
      // @ts-ignore
      reverseDirection={content.reverse}
    >
      <ImageContainer>
        <Video
          videoFile
          // @ts-ignore
          videoSrcURL={data.firstColumn.videoFile.asset.url}
          showControls
          width="100%"
        />
      </ImageContainer>
      <SecondColumnContainer
        // @ts-ignore
        reverse={data.reverse}
      >
        <BlocksContent
          // @ts-ignore
          blocks={data?.secondColumn._rawColumnContent}
        />
      </SecondColumnContainer>
    </ImageTextBlockContainer>
  );
};

export default ImageVideoColumn;
