import React from "react";

import Layout from "./src/components/shared/layout";
import "./static/font.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
}

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
