import React, { ReactNode, useState, RefObject } from "react";
import ReactSlick, { Settings } from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { assets } from "@util/constants";
import { Assets } from "@util/types";

interface Props {
  children: ReactNode;
  hideArrow?: boolean;
  moreSettings?: any;
  sliderRef?: RefObject<ReactSlick>;
  variableWidth?: boolean;
  adaptiveHeight?: boolean;
  infinite?: boolean;
  leftArrowAsset?: Assets;
  rightArrowAsset?: Assets;
  slidesToShow?: number;
  autoplay?: boolean;
  fade?: boolean;
}

const Carousel = ({
  children,
  hideArrow,
  sliderRef,
  variableWidth,
  adaptiveHeight,
  infinite,
  leftArrowAsset,
  rightArrowAsset,
  slidesToShow,
  autoplay,
  fade
}: Props) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings: Settings = {
    dots: false,
    infinite: infinite ?? true,
    speed: 500,
    autoplay: autoplay ?? false,
    autoplaySpeed: 3000,
    slidesToShow: slidesToShow ?? 1,
    slidesToScroll: 1,
    arrows: hideArrow ? false : true,
    pauseOnHover: true,
    nextArrow: hideArrow ? undefined : (
      <Arrow
        src={assets[rightArrowAsset ?? "rightArrow"]}
        alt={`next-index-${slideIndex + 1}`}
        width="20px"
        height="20px"
      />
    ),
    prevArrow: hideArrow ? undefined : (
      <Arrow
        src={assets[leftArrowAsset ?? "leftArrow"]}
        alt={`next-index-${slideIndex - 1}`}
        width="20px"
        height="20px"
      />
    ),
    beforeChange: (_current, next) => setSlideIndex(next),
    variableWidth: variableWidth ? true : false,
    adaptiveHeight: adaptiveHeight ? true : false,
    fade: fade ? true : false
  };

  return (
    <ReactSlick ref={sliderRef} {...settings}>
      {children}
    </ReactSlick>
  );
};

export default Carousel;

const Arrow = styled.img`
  object-fit: contain;
`;
