const client = require("@sanity/client");

import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";

export const sanityClient = client({
  projectId: process.env.SANITY_PROJECT_ID ?? "alh6ciiu",
  dataset: process.env.SANITY_DATASET ?? "production",
  useCdn: true,
});

export const imageBuilder = imageUrlBuilder(sanityClient).auto("format");

export function urlForImageRef(source?: SanityImageSource) {
  return imageBuilder.image(source ?? "");
}
