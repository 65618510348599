import React from "react";
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby";

import { Container, GradientBar, H3 } from "@util/standard";
import styled from "styled-components";
import { Query } from "@graphql-types";
import { Link } from "./sub";
import {
  colors,
  defaultTheme,
  fontSizes,
  MOBILE_BREAKPOINT,
  pages,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Theme } from "@util/types";
import SocialIcons from "./socialIcons";

interface Props {
  theme: Theme;
}

const Footer = ({ theme }: Props) => {
  const { sanityFooter }: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        CtaText
        CtaLink {
          url
          linktext
          internallink
        }
        links {
          _key
          title
          links {
            _key
            internallink
            linktext
            newwindow
            url
          }
        }
        mobileLinks {
          _key
          title
          links {
            url
            newwindow
            linktext
            internallink
            _key
          }
        }
        contactDetails {
          _key
          _type
          title
          link {
            internallink
            linktext
            newwindow
            url
          }
        }
        socialLinks {
          title
          linkTo
          socialIcon {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  if (!sanityFooter) return null;
  const isDefaultTheme =
    theme.background === defaultTheme.background &&
    theme.text === defaultTheme.text;

  return (
    <Wrapper
      theme={{
        text: isDefaultTheme ? colors.white : theme.text,
        background: isDefaultTheme ? colors.flightBlue : theme.background,
      }}
    >
      <Container
        width="85%"
        mobileDimensions={{ width: "80%" }}
        flexDirection="column"
        className="footer-container"
      >
        <Text>
          <Span>{sanityFooter.CtaText} </Span>
          <GatsbyLink to={pages.contact} style={{ textDecoration: "none" }}>
            <Span hasHover noWordWrap className="underline">
              {sanityFooter.CtaLink?.linktext}
            </Span>
          </GatsbyLink>
        </Text>
        <SocialIcons socialLinks={sanityFooter.socialLinks} />
        <DesktopContainer justifyContent="space-between" width="100%">
          <ContactDetailsWrapper>
            {sanityFooter.contactDetails &&
              sanityFooter.contactDetails.map((item) => {
                if (item == null) return null;

                return (
                  <Container width="100%" margin="0">
                    <p className="contact-detail-title">{item.title}</p>
                    <Link className="contact-detail-link" bold {...item.link} />
                  </Container>
                );
              })}
          </ContactDetailsWrapper>
          <Container margin="0" justifyContent="flex-end" width="50%">
            {sanityFooter.links?.map((linkGroup) => {
              if (linkGroup == null) {
                return null;
              }
              const { title, links, _key } = linkGroup;

              return (
                <Container
                  width="max-content"
                  key={_key}
                  flexDirection="column"
                  margin="0 auto"
                >
                  <CategoryTitle>{title}</CategoryTitle>
                  {links?.map((link) => (
                    <StyledLink bold key={link?._key} {...link} />
                  ))}
                </Container>
              );
            })}
          </Container>
        </DesktopContainer>

        <MobileContainer flexDirection="column">
          <Container width="100%" justifyContent="space-between">
            {sanityFooter.mobileLinks?.map((linkGroup) => {
              if (linkGroup == null) {
                return null;
              }
              const { title, links, _key } = linkGroup;

              return (
                <Container autoWidthNoMargin key={_key} flexDirection="column">
                  <CategoryTitle>{title}</CategoryTitle>
                  {links?.map((link) => (
                    <StyledLink bold key={link?._key} {...link} />
                  ))}
                </Container>
              );
            })}
          </Container>
          <ContactDetailsWrapper>
            {sanityFooter.contactDetails &&
              sanityFooter.contactDetails.map((item) => {
                if (item == null) return null;

                return (
                  <Container width="100%" margin="0" isMobileColumn>
                    <p className="contact-detail-title">{item.title}</p>
                    <Link className="contact-detail-link" bold {...item.link} />
                  </Container>
                );
              })}
          </ContactDetailsWrapper>
        </MobileContainer>
      </Container>
      <GradientBar />
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div<{ theme: Theme }>`
  width: 100%;
  background-color: ${(props) => props.theme.background};
  position: relative;
  padding: 100px 0 100px 0;
  .underline {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
  }
  .social-link {
    opacity: 1;
    &:hover {
      opacity: 0.8;
    }
  }
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  span {
    color: ${(props) => props.theme.text} !important;
    background-color: ${(props) => props.theme.background};
  }

  a,
  p {
    font-size: 20px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 50px 0 50px 0;
  }

  a {
    line-height: 100%;
    padding-bottom: 2px;
    width: fit-content;

    background: linear-gradient(
        to right,
        rgba(100, 200, 200, 0),
        rgba(100, 200, 200, 0)
      ),
      linear-gradient(270deg, #fff 14.11%, #fff 65.01%), transparent;
    background-size: 100% 1px, 0 1px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;

    &:hover {
      background-size: 0 1px, 100% 1px;
      cursor: url("/cursorClick.svg"), auto;
    }
  }
`;

const Text = styled(H3)`
  display: inline-block;
  margin-bottom: 80px;
  max-width: 821px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 40px;
  }
`;

const DesktopContainer = styled(Container)`
  width: 100%;
  margin: 0;
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileContainer = styled(Container)`
  width: 100%;
  margin: 0;
  justify-content: space-between;

  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const CategoryTitle = styled.h4`
  font-size: ${fontSizes.p.default}px;
  font-weight: 400;
  opacity: 0.5;
`;

const Span = styled.span<{
  noWordWrap?: boolean;
  hasHover?: boolean;
  bold?: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "bold" : "400")};
  font-size: 50px;
  line-height: 63px;

  ${({ noWordWrap }) => noWordWrap && `white-space: nowrap;`}

  ${({ hasHover }) =>
    hasHover &&
    `:hover {
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-decoration-offset: 2px;
  }`};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 34px;
    line-height: 40px;
  }
`;

const StyledLink = styled(Link)`
  margin-bottom: 10px;
`;

const ContactDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-bottom: 12px;

  .contact-detail-title {
    margin: 0;
    line-height: 100%;

    opacity: 0.5;
    text-align: left;
  }
  .contact-detail-link {
    display: flex;
    margin-left: 5px;
    line-height: 100%;
    p {
      font-weight: normal;
      margin: 0;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin-top: 50px;
    .contact-detail-title,
    .contact-detail-link {
      width: 100%;
    }

    .contact-detail-title {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;
