import React, { useState } from "react";

import HubspotForm from "react-hubspot-form";
import { SanityHubspotFormBlock } from "@graphql-types";
import { Container } from "@util/standard";
import BlocksContent from "./blocksContent";
import styled from "styled-components";
import { TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityHubspotFormBlock;
}

function HubspotFormBlock({ data }: Props) {
  if (data == null) return null;
  const [submitted, setSubmitted] = useState(false);

  const { formId, portalId, formText } = data;
  return (
    <Container
      width="65%"
      tabletWidth="100%"
      margin="auto"
      flexDirection="column"
    >
      <TextWrapper>
        <BlocksContent blocks={formText?._rawColumnContent} />
      </TextWrapper>
      <Container width="100%">
        {submitted ? (
          <h3>Thank you</h3>
        ) : (
          <FormWrapper>
            <HubspotForm
              portalId={portalId}
              formId={formId}
              onSubmit={() => setSubmitted(true)}
              loading={<div>Loading...</div>}
            />
          </FormWrapper>
        )}
      </Container>
    </Container>
  );
}

export default HubspotFormBlock;

const FormWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  div {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  h2 {
    font-size: 35px;
    margin-bottom: 50px;
    line-height: 55px;
    text-align: center;
  }
  br {
    display: none;
  }
  strong {
    font-size: 25px;
    line-height: 30px;
  }
  p,
  li {
    font-size: 20px;
    line-height: 24px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p,
    strong {
      font-size: 15px;
      line-height: 18px;
    }
  }
`;
