import React from "react";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import SanityImage from "gatsby-plugin-sanity-image";

import { MOBILE_BREAKPOINT, colorsRGB, altTexts } from "@util/constants";
import { getRandomArbitrary } from "@util/helper";
import { SanityImageWithMeta } from "@graphql-types";

const StyledImage = styled(Img)<{
  width?: string;
  mobileWidth?: string;
  grayscale?: number;
  boxShadow?: boolean;
}>`
  ${({ boxShadow }) =>
    boxShadow && `box-shadow: 0 0 10px 0 ${colorsRGB.black(0.1)};`}
  ${({ width }) => (width ? `width:${width}` : "width: 100%;")}
  ${({ grayscale }) => grayscale && `filter: grayscale(${grayscale});`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileWidth }) =>
      mobileWidth ? `width:${mobileWidth}` : "width:100%"}
  }
`;

const BackgroundImageWrapper = styled.div<{ opacity?: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  opacity: ${(props) => props.opacity && `opacity: ${props.opacity}`};
  transition: all 0.3s;
`;

interface Props {
  fixed?: FixedObject;
  fluid?: FluidObject;
  objectFit?: "contain" | "cover" | "fill" | "scale-down" | "none";
  minHeight?: string;
  maxHeight?: string;
  height?: string;
  isBackground?: boolean;
  backgroundStyle?: any;
  width?: string;
  padding?: string;
  mobileWidth?: string;
  altText?: string;
  load?: "auto" | "lazy" | "eager";
  grayscale?: number;
  boxShadow?: boolean;
  data?: SanityImageWithMeta;
}

const Image = (props: Props) => {
  if (props.data) {
    const { hotspot } = props.data;

    if (hotspot) {
      if (props.isBackground) {
        return (
          <BackgroundImageWrapper>
            <SanityImage
              {...props.data}
              alt={props.data?.altText ?? "bg-image"}
              itemProp="image"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </BackgroundImageWrapper>
        );
      }
      return (
        <SanityImage
          {...props.data}
          alt={props.data?.altText ?? "bg-image"}
          itemProp="image"
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
        />
      );
    }
  }

  if (props.isBackground && props.fluid) {
    return (
      <BackgroundImage
        fluid={props.fluid}
        style={props.backgroundStyle}
        alt={props.altText ?? altTexts[getRandomArbitrary(0, 2)]}
      />
    );
  }

  if (props.fixed) {
    return (
      <Img
        fixed={props.fixed}
        imgStyle={{
          objectFit: props.objectFit ?? "none",
        }}
        alt={props.altText ?? altTexts[getRandomArbitrary(0, 2)]}
      />
    );
  }
  if (props.fluid) {
    const { minHeight, maxHeight, height, width, load, grayscale, boxShadow } =
      props;
    return (
      <StyledImage
        //@ts-ignore
        fluid={props.fluid}
        imgStyle={{
          objectFit: props.objectFit ?? "initial",
        }}
        style={{ minHeight, maxHeight, height, width }}
        mobileWidth={props.mobileWidth}
        itemProp="image"
        alt={props.altText ?? altTexts[getRandomArbitrary(0, 2)]}
        loading={load}
        grayscale={grayscale}
        boxShadow={boxShadow}
      />
    );
  }

  return null;
};

export default Image;
