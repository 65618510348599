import React, { ReactNode } from "react";
import VisibilitySensor from "react-visibility-sensor";
// import { Spring, animated, SpringConfig } from "react-spring";

import { AnimationDirection } from "@util/types";

interface Props {
  children: ReactNode;
  animationType: AnimationDirection;
  loop?: boolean;
  delay?: number;
  disablePartialVisibility?: boolean;
  disableVisibilitySensor?: boolean;
  config?: Partial<SpringConfig>;
}

function AnimationContainer(props: Props) {
  const {
    children,
    animationType,
    loop,
    delay,
    disablePartialVisibility,
    disableVisibilitySensor,
    config,
  } = props;

  return (
    <VisibilitySensor partialVisibility={!disablePartialVisibility}>
      {({ isVisible }) => (
        // <Spring
        //   config={config}
        //   // @ts-ignore
        //   to={
        //     isVisible || disableVisibilitySensor ? animationType.to : undefined
        //   }
        //   from={animationType.from ? animationType.from : undefined}
        //   loop={loop}
        //   delay={delay ? delay : 0}
        // >
        //   {(styles) => <animated.div style={styles}>{children}</animated.div>}
        // </Spring>
        <div>{children}</div>
      )}
    </VisibilitySensor>
  );
}

export default AnimationContainer;
