import React from "react";

import { Maybe, SanitySocialLink } from "@graphql-types";
import { Container } from "@util/standard";

interface Props {
  socialLinks?: Maybe<Maybe<SanitySocialLink>[]> | undefined;
}

export default function SocialIcons({ socialLinks }: Props) {
  if (!socialLinks) return null;

  return (
    <Container width="max-content" margin="0" tabletMargin="0 0 40px 0">
      {socialLinks?.map((socialLink) => {
        if (socialLink == null || socialLink.linkTo == null) return null;
        const { linkTo, socialIcon } = socialLink;
        if (socialIcon?.asset?.url == null) return null;
        return (
          <Container className="social-link" margin="0 20px 0 0">
            <a href={linkTo} target="_blank">
              {getSocialIcon(linkTo, socialIcon?.asset.url)}
            </a>
          </Container>
        );
      })}
    </Container>
  );
}

const getSocialIcon = (url: Maybe<string>, imgUrl: Maybe<string>) => {
  if (!url) return null;
  if (url.indexOf("facebook") >= 0) {
    return <FacebookIcon />;
  }
  if (url.indexOf("instagram") >= 0) {
    return <InstagramIcon />;
  }
  if (url.indexOf("linkedin") >= 0) {
    return <LinkedInIcon />;
  }

  if (imgUrl) return <img src={imgUrl} />;

  return null;
};

const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Facebook social"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        fill="currentColor"
        d="M23 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h12v-9h-3v-4h3V8c0-3 2-4 4-4a25 25 0 0 1 3 0v3h-2l-1 2v2h3v4h-3v9h6a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z"
      />
    </svg>
  );
};

const LinkedInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Linkedin social"
      viewBox="0 0 455 455"
      width="24"
      height="24"
    >
      <g fill="currentColor">
        <path d="M246 204zM0 0v455h455V0H0zm142 378H74V175h68v203zm-34-231h-1c-22 0-37-15-37-35s15-35 38-35 38 15 38 35-15 35-38 35zm277 231h-68V269c0-27-9-46-34-46-18 0-29 13-34 25-2 4-3 10-3 17v113h-67V175h67v29c9-14 25-34 61-34 45 0 78 29 78 92v116z" />
      </g>
    </svg>
  );
};

const InstagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Instagram social"
      viewBox="0 0 256 256"
      width="24"
      height="24"
    >
      <path
        fill="currentColor"
        d="m128 23 52 1c12 0 19 2 23 4 6 3 11 5 15 10 5 4 7 9 10 15 2 4 4 11 4 23a890 890 0 0 1-4 127c-3 6-5 11-10 15-4 5-9 7-15 10-4 2-11 4-23 4a888 888 0 0 1-127-4c-6-3-11-5-15-10-5-4-7-9-10-15-2-4-4-11-4-23a888 888 0 0 1 4-127c3-6 5-11 10-15 4-5 9-7 15-10 4-2 11-4 23-4l52-1m0-23L75 1C62 1 52 4 44 7s-15 7-23 14c-7 8-11 15-14 23S1 62 1 75a908 908 0 0 0 6 137c3 8 7 15 14 23 8 7 15 11 23 14s18 6 31 6a908 908 0 0 0 137-6c8-3 15-7 23-14 7-8 11-15 14-23s6-18 6-31a908 908 0 0 0-6-137c-3-8-7-15-14-23-8-7-15-11-23-14s-18-6-31-6l-53-1zm0 62a66 66 0 1 0 0 132 66 66 0 0 0 0-132zm0 109a43 43 0 1 1 0-86 43 43 0 0 1 0 86zm84-111a15 15 0 1 1-31 0 15 15 0 0 1 31 0z"
      />
    </svg>
  );
};
