import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { FixedObject } from "gatsby-image";

import { A, P, standardUnderline } from "@util/standard";
import { Image } from "@sub";
import { Maybe, Scalars } from "@graphql-types";
import styled from "styled-components";
import { colors } from "@util/constants";
import { Color } from "@util/types";
interface Props {
  linktext?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  internallink?: Maybe<Scalars["Boolean"]>;
  newwindow?: Maybe<Scalars["Boolean"]>;
  asset?: Maybe<FixedObject>;
  className?: string;
  color?: Color;
  isUnderlined?: boolean;
  hoverColor?: Color;
  padding?: string;
  animation?: string;
  appendedUrl?: string;
  bold?: boolean;
  margin?: string;
}

const StyledGatsbyLink = styled(GatsbyLink)<{
  padding?: string;
  underlined?: number;
  animation?: string;
  color?: Color;
  margin?: string;
}>`
  line-height: 100%;
  user-select: none;
  text-decoration: ${(props) =>
    props.underlined === 1 ? `underline` : `none`};
  ${({ padding }) => padding && `padding:${padding}`};
  ${({ margin }) => margin && `margin:${margin}`};

  ${({ color }) => color && `color:${colors[color]}`};

  line-height: 100%;
  padding-bottom: 2px;

  background: linear-gradient(
      to right,
      rgba(100, 200, 200, 0),
      rgba(100, 200, 200, 0)
    ),
    linear-gradient(
      270deg,
      ${(props) => (props.color ? colors[props.color] : "#fff")} 14.11%,
      ${(props) => (props.color ? colors[props.color] : "#fff")} 65.01%
    ),
    transparent;
  background-size: 100% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;

  &:hover {
    background-size: 0 1px, 100% 1px;
    cursor: url("/cursorClick.svg"), auto;
  }
`;

const ImageWrapper = styled.div`
  &:hover {
    opacity: 0.6;
  }
`;

const StyledP = styled(P)<{
  color?: Color;
  hoverColor?: Color;
}>`
  line-height: 100%;
  text-decoration: none !important;
  ${({ color }) => color && `color:${colors[color]}`};
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
      color: ${colors[hoverColor]};
    }`}

  margin: 0;
`;

const Link = ({
  linktext,
  url,
  internallink,
  newwindow,
  asset,
  className = "",
  color,
  hoverColor,
  isUnderlined,
  padding,
  animation,
  appendedUrl,
  bold,
  margin,
}: Props) => {
  if (internallink || appendedUrl) {
    const to = url === "/" ? "/" : appendedUrl ? appendedUrl! : `/${url}/`;
    return (
      <StyledGatsbyLink
        activeClassName="active"
        className={className}
        to={to}
        padding={padding}
        underlined={isUnderlined ? 1 : 0}
        animation={animation}
        color={color}
        margin={margin}
      >
        {asset ? (
          <ImageWrapper>
            <Image fixed={asset} />
          </ImageWrapper>
        ) : (
          <StyledP
            hoverColor={hoverColor}
            color={color}
            underlined={isUnderlined}
            bold={bold}
          >
            {linktext}
          </StyledP>
        )}
      </StyledGatsbyLink>
    );
  }

  return (
    <A
      href={url ?? "/"}
      className={className}
      target={newwindow ? "_blank" : ""}
      rel={newwindow ? "noreferrer" : ""}
      underlined={isUnderlined}
      color={color}
    >
      {asset ? (
        <ImageWrapper>
          <Image fixed={asset} />
        </ImageWrapper>
      ) : (
        <StyledP bold={bold} color={color}>
          {linktext}
        </StyledP>
      )}
    </A>
  );
};

export default Link;
