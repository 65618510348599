import styled from "styled-components";
import AnimatedNumber from "animated-number-react";

import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  colorsRGB,
  fontSizes,
  LAPTOP_BREAKPOINT,
  colors,
  SMALL_LAPTOP_BREAKPOINT,
} from "@util/constants";
import { Container, P, GridContainer, H1 } from "@util/standard";
import { Maybe } from "@graphql-types";

export const Wrapper = styled(Container)`
  width: 100%;
  margin: 0;
  flex-direction: column;
  margin: 35px auto;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 25px auto;
    width: 100%;
  }
`;

export const PageWrapper = styled.div<{
  background?: Maybe<string>;
  text?: Maybe<string>;
}>`
  ${({ text }) =>
    text &&
    `h1, h2, h3, h4, p, .heading-1, .heading-2, .heading-3, span, a{
    color: ${text};
  }
 
  .accordion, .column-content {
    border-color: ${text};
  }
  .expand-icon path {
    stroke: ${text};
  }
  `}

  &:before {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    ${({ background }) => background && `background-color: ${background};`}
  }
`;

export const CenteredBlockContainer = styled(Container)`
  margin-top: 20px;
  width: 70%;
  flex-direction: column;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    width: 70%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 5px;
  }
`;

export const OverviewContainer = styled(Container)`
  width: 100%;
  margin: 0;
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const Title = styled(P)<{ marginTop?: number }>`
  font-weight: "bold";
  margin: ${(props) => `${props.marginTop ?? 0}px 0 0 0`};
`;

export const Text = styled(P)`
  margin: 7px 0 0 0;
`;

export const CtasContainer = styled(Container)`
  width: auto;
  margin: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: row;
    margin-top: 25px;
  }
`;

export const InfoWrapper = styled(GridContainer)`
  width: 60%;
  margin: 0;
  justify-content: space-between;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const InfoContainer = styled(Container)`
  width: auto;
  margin: 0;
  flex-direction: column;
`;

export const Img = styled.img<{
  width?: number;
  height?: number;
  boxShadow?: boolean;
}>`
  // box-shadow: 0px 0px 10px 0px ${colorsRGB.black()};
  ${({ boxShadow }) =>
    boxShadow && `box-shadow: 0 0 10px 0 ${colorsRGB.black(0.1)};`}
  width: ${(props) => props.width ?? 100}%;
  ${({ height }) => height && `height: ${height}%;`}
  object-fit: cover;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: 100%;
  }
`;

export const GroupedContainer = styled(Container)`
  width: 100%;
  grid-column-gap: 3%;
  margin: 80px auto;
  justify-content: space-evenly;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 60px auto;
    grid-row-gap: 20px;
    flex-direction: column;
  }
`;

export const TeamContainer = styled(Container)`
  width: 70%;
  flex-direction: column;
  margin-bottom: 80px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-bottom: 60px;
  }
`;

export const ImageTextBlockContainer = styled(Container)`
  width: 100%;
  margin: 0px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    margin: 25px 0;
  }
`;

export const BlockContentContainer = styled(Container)`
  width: 100%;
  margin: 80px 0;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    margin: 40px 0;
  }
`;

export const SecondColumnContainer = styled(Container)<{ reverse?: boolean }>`
  width: 35%;
  margin: ${(props) =>
    props.reverse ? "auto 80px auto auto" : "auto auto auto 80px"};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: 60px auto 0 auto;
  }
`;

export const ImageContainer = styled(Container)`
  width: 50%;
  margin: 0;
  height: auto;
  overflow: hidden;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

export const Stat = styled(AnimatedNumber)`
  font-size: ${fontSizes.h1.default}px;
  font-weight: "bold";
  color: ${colors.white};

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h1.mobile}px;
  }
`;

export const Metric = styled.span`
  font-size: ${fontSizes.h1.default}px;
  font-weight: "bold";
  color: ${colors.white};
  margin: 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h1.mobile}px;
  }
`;

export const StatContainer = styled(GridContainer)`
  margin: 80px auto;
  background-color: ${colors.flightBlue};
  padding: 80px 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 60px auto;
  }
`;

export const BlogTitle = styled(H1)`
  margin: 36px 0px 103px 0px;
  font-size: 40px;
  line-height: 52px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 15px 0px 50px 0px;
    font-size: 34px !important;
    line-height: 1.2 !important;
  }
`;

export const VideoWrapper = styled(Container)<{
  backgroundUrl?: string | null | undefined;
  tabletMarginOverride?: string;
  height?: string;
}>`
  position: relative;
  background-image: url(${(props) => props.backgroundUrl});
  width: 95%;
  margin: auto auto 50px auto;
  height: ${(props) => props.height ?? "92vh"};

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    height: 50vh;
    margin: 50px auto;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 30vh;
    margin: 100px auto;
    ${({ tabletMarginOverride }) =>
      tabletMarginOverride && `margin: ${tabletMarginOverride};`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 30vh;
    width: 100%;
    margin: 50px auto;
    ${({ tabletMarginOverride }) =>
      tabletMarginOverride && `margin: ${tabletMarginOverride};`};
  }
`;
