import { useEffect, useRef, useState } from "react";
import { isBrowser } from "./helper";

export function useHubspotCallback() {
  const [hubspot, setHubspot] = useState<any>();

  if (!isBrowser()) return;

  const handleLoad = () => {
    if (window._hspb_loaded) {
      const _hsq = window._hsq ? window._hsq : [];
      if (!hubspot && _hsq != []) {
        setHubspot(_hsq);
      }
    }
  };

  useEventListener("message", handleLoad);

  useEffect(() => {
    if (hubspot) {
      hubspot.push([
        "addPrivacyConsentListener",
        function (consent: any) {
          if (consent.allowed) {
            const gtmCookie = getCookie("gatsby-gdpr-google-tagmanager");
            if (!gtmCookie) {
              setCookie("gatsby-gdpr-google-tagmanager", "true", 360);
            }
          }
        },
      ]);
    }
  }, [hubspot]);
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

export function useEventListener(
  eventName: string,
  handler: (args: any) => void,
  elementToListen?: any
) {
  if (!isBrowser()) return;
  const element = elementToListen ?? window;
  const savedHandler = useRef<typeof handler>();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]);
}

export function setCookie(cName: string, cValue: string, expDays: number) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

function getCookie(cName: string) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}
