import React, { createContext, ReactNode } from "react";

import { Theme } from "@util/types";

interface ThemeContextProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

interface Props {
  children: ReactNode;
  value: ThemeContextProps;
}

const defaultState = {
  theme: {
    text: "#0000FF",
    background: "#FFFFFF"
  },
  setTheme: () => {}
};

export const ThemeContext = createContext<ThemeContextProps>(defaultState);

export default function ThemeProvider({ children, value }: Props) {
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}
