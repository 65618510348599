import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

import { colors, fontSizes, MOBILE_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";

interface Props {
  titleChildren?: ReactNode;
  children?: ReactNode;
  openOverride?: boolean;
  openOnLoad?: boolean | null | undefined;
  width?: string;
  margin?: string;
  useBorder?: boolean;
  padding?: string;
  useTopBorder?: boolean;
}

const Svg = styled.svg<{ open?: boolean }>`
  position: relative;
  top: 0;
  height: 25px;
  right: 3px;
  ${({ open }) =>
    open ? `transform: rotateZ(-45Deg);` : `transform: rotateZ(90Deg);`};
  transition: all 0.2s;
`;

const TitleWrapper = styled(Container)<{ isOpen?: boolean }>`
  justify-content: space-between;
  align-items: left;
  width: 100%;
  cursor: url("/cursorClick.svg"), auto;
  h2,
  h3,
  h4,
  p,
  span {
    font-weight: normal;
    font-size: 20px !important;
  }
  span {
    margin: 25px 0 !important;
    max-width: 90%;
  }
  svg {
    margin: auto 0;
  }
  margin: 0;
  ${({ isOpen }) => isOpen && `margin: 0 0 40px 0;`};
`;

const AccordionWrapper = styled(Container)<{
  useBorder?: boolean;
  useTopBorder?: boolean;
}>`
  ${({ useBorder }) =>
    useBorder && `border-bottom: 1px solid ${colors.flightBlue};`};
  ${({ useTopBorder }) =>
    useTopBorder && `border-top: 1px solid ${colors.flightBlue};`};
`;

const TextWrapper = styled(Container)`
  p {
    font-size: ${fontSizes.p.default}px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    p {
      font-size: ${fontSizes.p.mobile}px;
    }
  }
`;

function Accordion({
  titleChildren,
  children,
  openOverride,
  openOnLoad,
  width,
  margin,
  padding,
  useBorder,
  useTopBorder,
}: Props) {
  const [dropDownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (openOverride) {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  }, [openOverride]);

  useEffect(() => {
    if (openOnLoad) {
      setDropdownOpen(true);
    }
  }, []);

  return (
    <AccordionWrapper
      flexDirection="column"
      width={width ?? "100%"}
      margin={margin}
      useBorder={useBorder}
      useTopBorder={useTopBorder}
      className="accordion"
    >
      <TitleWrapper
        width="100%"
        justifyContent="space-between"
        margin={dropDownOpen ? "0 0 20px 0" : "0"}
        onClick={() => setDropdownOpen(!dropDownOpen)}
      >
        {titleChildren}
        <ExpandIcon open={dropDownOpen} alt="accordion-arrow-icon" />
      </TitleWrapper>
      {dropDownOpen && (
        <TextWrapper flexDirection="column" width="100%" margin="0 0 20px 0">
          {children}
        </TextWrapper>
      )}
    </AccordionWrapper>
  );
}

export default Accordion;

const ExpandIcon = (props: any) => {
  return (
    <Svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="expand-icon"
      {...props}
    >
      <path
        d="M15.9265 0.801758V30.8018"
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M0.926514 15.8018H30.9265"
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </Svg>
  );
};
