export const BUTTON_HEIGHT = 40;
export const HEADER_HEIGHT = 80;
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;
export const ANIMATION_TRANSITION = 0.5;

export const fontSizes = {
  p: { default: 22, mobile: 22 },
  h1: { default: 50, mobile: 34 },
  h2: { default: 30, mobile: 23 },
  h3: { default: 30, mobile: 22 },
};

export const mobileFontSizes = {
  p: 15,
  h1: 50,
  h2: 32,
  h3: 24,
};

export const colors = {
  transparent: "transparent",
  white: "#FFFFFF",
  black: "#000000",
  flightBlue: "#0000FF",
  olive: "#DFE4E0",
  washedBlueFaded: "#DFE4E0",
  teal: "#69B9BE",
  green: "#79BE88",
  purple: "#CA91D8",
  orange: "#FF8946",
  gold: "#FFC034",
};

export const defaultTheme = {
  text: colors.flightBlue,
  background: colors.white,
};

export const brandColors = {
  juice: "#FFD48A",
  purple: "#D1BDD4",
  washedBlue: "#BACDD1",
  peach: "#FAD6B2",
  green: "#C9E0C4",
  lightGreen: "#C9E0C4",
  transparent: "transparent",
};

export const brandColorsArray = Object.keys(brandColors).map(
  // @ts-ignore
  (key) => brandColors[key]
);

export const brandRGBColorsArray = [
  "rgb(255, 212, 138, 0.9)",
  "rgb(209, 189, 212, 0.9)",
  "rgb(186, 205, 209, 0.9)",
  "rgb(250, 214, 178, 0.9)",
  "rgb(201, 224, 196, 0.9)",
];

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
};

export const assets = {
  flight: require("@assets/flight.png"),
  logo: require("@assets/logo.png"),
  underline: require("@assets/underline.svg"),
  downArrow: require("@assets/down-arrow.svg"),
  leftArrow: require("@assets/left-arrow.svg"),
  rightArrow: require("@assets/right-arrow.svg"),
  hamburger: require("@assets/hamburger.svg"),
  logoWhite: require("@assets/logo-white.png"),
  close: require("@assets/close.svg"),
  leftArrowBlue: require("@assets/left-blue.svg"),
  rightArrowBlue: require("@assets/right-blue.svg"),
  downArrowBlue: require("@assets/down-arrow-blue.svg"),
  notFound: require("@assets/404.svg"),
  laptopFrame: require("@assets/laptopFrame.svg"),
  scrollIcon: require("@assets/scrollIcon.svg"),
  scrollArrow: require("@assets/scrollArrow.svg"),
  expand: require("@assets/expand.svg"),
  motto: require("@assets/motto.svg"),
};

export const pages = {
  home: "/",
  about: "/about-flight-digital",
  contact: "/contact",
  notFound: "/404",
  work: "/project",
  updates: "/updates",
  whatWeDo: "/what-we-do",
  requestAudit: "/request-audit",
  requestComparison: "request-comparison",
};

export const buttonStyle = {
  base: {
    bg: `${colors.transparent}`,
    text: `${colors.flightBlue}`,
    border: `${colors.flightBlue}`,
    hoverBg: `${colors.flightBlue}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.flightBlue}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.white}`,
  },
  whiteInverse: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.flightBlue}`,
    hoverText: `${colors.flightBlue}`,
  },
};

export const flightAscii = `
       ,*.*,,*,**.,,*.@@&&&&&&&&&&&&&*****,.*//.**,
       ,*.*,,*,*@&&&&&&&&&&&&&&&&&&&&&&&&&@.*//.**,
       ,*.*,,&&&&&&&&&&*********#%&&&&&&&&&@/.**,
       ,*.&&&&&&&**,***/*********//*,*#&&&&&&&@*,
       ,@&&&&&&/*****,***,,/**/.,/*//*,***,.&&&&&&&
......&&&&&**/*,,******,.     ,/*//*/.,*,. /*&&&&&&......
/////&&&&&*,***   ,&&&&&&@.     &&&&&&&@,.   //,*&&&&&
****&&&&&        %&&&&&&&&&&&/%&&&&&&&&&&&****,,,,&&&&&
,,*&&&&%    *****&&&&&&&&&&&&*&&&&&&&&&&&&*****,,,,%&&&&...
..&&&/////     &&&&&&&&&&&&,&&&&&&&&&&&&,,,,,,,,,,#&&&&//
.&&&&&((((((/////*&&&&&&&&&&&/*&&&&&&&&&&.     ......&&&&&*
*&&&&///////******.#&&&&&&%,,   ,%&&&&&//      ,,,,,,,&&&&&
&&&.......       **@@@@**,(/,**,*****//     ********#&&&&
&&&&**************@&&&&&&&&&(/,&&&&&&&*///////*********&&&&
&&&&*******//////&&&&&&&&&&&&/&&&&&&&&&&///////,,,,,,,,&&&&
&&&&*************#&&&&&&&&&&&&&&&&&&&&&%*******/*******&&&&
&&&&*******(//////&&&&&&&&&&&, &&&&&&&&,,,,,,,,........&&&&
&&&&%*******      .*&&&&&&&*    .(%#&*/       ........&&&&&
*&&&&,,,,,,,,     .******.**    ..**.*/      /////////&&&
,&&&&&,,,,,,,      ,&&&&&&&@/   ,.**.*/    ..........&&&&&
..&&&&&*****,,,,,,&&&&&&&&&&&(((((/*.*/   **********&&&&&*/
,,,&&&&&     .....&&&&&&&&&&&&************,........&&&&&***
    &&&&&    *****&&&&&&&&&&&,,,,,,,,,,****,/,,,,,&&&&&*,,,
(((((&&&&&& .//(((#&&&&&&&&&&.......,,,****,**  &&&&&&
,,,,,,&&&&&&&/*/****#&&&&&&*,,,,,****,,****,**&&&&&......
////////%&&&&&&/*.***,****//(((((****,,****.&&&&&
////////**#&&&&&&&@,*.,*****(**,*****,,*@&&&&&&**
////////*****%&&&&&&&&&&@,*****,**@@&&&&&&&&&%**,**
////////*****,.**&&&&&&&&&&&&&&&&&&&&&&&&&**,,**,**
`;

export const animationType = {
  fadeIn: {
    to: { opacity: 1 },
    from: { opacity: 0 },
  },
  fadeOut: {
    to: { opacity: 0 },
    from: { opacity: 1 },
  },
  slideInLeft: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(-200px)", opacity: 0 },
  },
  slideInRight: {
    to: { transform: "translateX(0px)", opacity: 1 },
    from: { transform: "translateX(200px)", opacity: 0 },
  },
  slideInTop: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(-200px)", opacity: 0 },
  },
  slideInBottom: {
    to: { transform: "translateY(0px)", opacity: 1 },
    from: { transform: "translateY(200px)", opacity: 0 },
  },
  rotateLeft: {
    to: { transform: "rotateZ(0deg)" },
    from: { transform: "rotateZ(360deg)" },
  },
  rotateRight: {
    to: { transform: "rotateZ(360deg)" },
    from: { transform: "rotateZ(0deg)" },
  },
  noAnimation: {
    to: { opacity: 1 },
    from: { opacity: 1 },
  },
};

export const altTexts = [
  "Flight Digital",
  "Digital marketing agency",
  "Digital marketing agency Auckland",
];

export const vimeoUrls = {
  background: (url: string) =>
    `https://player.vimeo.com/video/${url}?background=1&autoplay=1&loop=1&byline=0&title=0`,
  standard: (url: string) => `https://player.vimeo.com/video/${url}`,
};

export const animations = {
  bounce: (otherTransforms?: string) => {
    return `@keyframes bounce {
    0% {
      transform: translateY(0) ${otherTransforms};
    }
    30% {
      transform: translateY(-20px) ${otherTransforms};
    }
    50% {
      transform: translateY(0) ${otherTransforms};
    }
    100% {
      transform: translateY(0) ${otherTransforms};
    }
  }
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;`;
  },
};
