import React from "react";
import styled from "styled-components";

import { Maybe, SanityBlock } from "@graphql-types";
import { Container, H1, H2 } from "@util/standard";
import { colors, assets, MOBILE_BREAKPOINT, fontSizes } from "@util/constants";
import { Color } from "@util/types";

const StyledH1 = styled(H1)`
  display: flex;
  line-height: 30px;
`;

const StyledH2 = styled(H2)`
  display: flex;
  line-height: 30px;
`;

const Span = styled.span<{
  fontFam?: string | null | undefined;
  fontSize?: number;
  mobileFontSize?: number;
  color?: Color;
  underlined?: boolean;
  lineBreak: boolean | undefined;
}>`
  font-weight: normal;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : fontSizes.h1.default}px;
  line-height: ${(props) =>
    props.fontSize ? props.fontSize * 1.3 : fontSizes.h1.default}px;

  ${({ lineBreak }) => lineBreak && `margin-top: 20px;`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ underlined }) =>
    underlined &&
    `background-image: url(${assets.underline});
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 20px;
  `}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${(props) =>
      props.mobileFontSize ? props.mobileFontSize : fontSizes.h1.mobile}px;
    line-height: ${(props) =>
      props.mobileFontSize
        ? props.mobileFontSize * 1.5
        : fontSizes.h1.mobile * 1.5}px;
  }
`;

interface Props {
  blocks: Maybe<SanityBlock>[] | null | undefined;
  fontSize?: number;
  mobileFontSize?: number;
  color?: Color;
  isH1?: boolean;
  excerpt?: any;
  floatingAnimation?: any;
}

const RichTextContent = (props: Props) => {
  const { excerpt, floatingAnimation } = props;

  const Child = () => (
    <Container autoWidthNoMargin flexDirection="column">
      {props.blocks?.map((block, index) => {
        if (block && block.children) {
          const child = block.children[0];
          const hasUnderline = child?.marks?.includes("underline");

          return (
            <>
              <Span
                id={`rich-text-${index}`}
                className="richTextSpan"
                key={block._key}
                fontSize={props.fontSize}
                color={props.color}
                underlined={hasUnderline}
                mobileFontSize={props.mobileFontSize}
                lineBreak={child?.text?.includes("\n")}
              >
                {child?.text}
                {index == 0 && excerpt && excerpt}
                {index == 3 && floatingAnimation && floatingAnimation}
              </Span>
            </>
          );
        }

        return null;
      })}
    </Container>
  );

  if (props.isH1) {
    return (
      <StyledH1>
        <Child />
      </StyledH1>
    );
  }

  return (
    <StyledH2>
      <Child />
    </StyledH2>
  );
};

export default RichTextContent;
