import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link as GatsbyLink, navigate } from "gatsby";

import { Container } from "@util/standard";
import {
  assets,
  colors,
  MOBILE_BREAKPOINT,
  pages,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Query } from "@graphql-types";
import { Link, MenuDrawer, Logo } from "@sub";
import { Theme } from "@util/types";
import SocialIcons from "./socialIcons";

interface Props {
  theme: Theme;
}

const Header = ({ theme }: Props) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { sanityNavigation, sanityFooter }: Query = useStaticQuery(graphql`
    {
      sanityNavigation {
        menuItems {
          title
          link {
            url
            newwindow
            linktext
            internallink
          }
        }
      }
      sanityFooter {
        socialLinks {
          title
          linkTo
          socialIcon {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    return () => {
      setDrawerVisible(false);
    };
  }, []);

  if (!sanityNavigation) return null;

  const handleOpenMenu = () => {
    setDrawerVisible(true);
  };

  const handleCloseMenu = () => {
    setDrawerVisible(false);
  };

  const MobileMenu = () => {
    return (
      <Container autoWidthNoMargin flex={1} flexDirection="column">
        <Container
          width="90%"
          padding="30px 0 0 0"
          justifyContent="space-between"
        >
          <Logo />
          <Img
            width={22}
            height={22}
            alt="close"
            src={assets.close}
            onClick={handleCloseMenu}
          />
        </Container>

        <StyledMobileWrapper
          flexDirection="column"
          width="70%"
          rowGap="20px"
          margin="80px auto 0 auto"
        >
          <MenuItem
            onClick={() => {
              setDrawerVisible(false);
              navigate(pages.home);
            }}
          >
            Home
          </MenuItem>
          {sanityNavigation.menuItems?.map((menuItem) => {
            if (menuItem == null) {
              return null;
            }

            const handleOnClick = () => {
              setDrawerVisible(false);
              navigate(`/${menuItem.link?.url}` ?? "/");
            };
            return (
              <MenuItem key={menuItem?.title} onClick={handleOnClick}>
                {menuItem?.title}
              </MenuItem>
            );
          })}
          <SocialIcons socialLinks={sanityFooter?.socialLinks} />
        </StyledMobileWrapper>
      </Container>
    );
  };

  return (
    <Wrapper theme={theme} className="header-wrapper">
      <HeaderContainer>
        <GatsbyLink to={pages.home}>
          <Logo />
        </GatsbyLink>
        <DesktopLinksContainer>
          {sanityNavigation.menuItems?.map((menuItem) => {
            const isBrowser = () => typeof window !== "undefined";
            const isUnderlined =
              isBrowser() &&
              window.location.href.includes(menuItem?.link?.url ?? "");
            return (
              <Link
                {...menuItem?.link}
                key={menuItem?.title}
                margin="0px 0px 0px 40px"
              />
            );
          })}
        </DesktopLinksContainer>
        <MobileWrapper>
          <svg
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleOpenMenu}
          >
            <path d="M0 1H22" stroke={theme.text} stroke-width="1.2" />
            <path d="M0 8H22" stroke={theme.text} stroke-width="1.2" />
            <path d="M0 15H22" stroke={theme.text} stroke-width="1.2" />
          </svg>
          <MenuDrawer
            visible={drawerVisible}
            onClose={handleCloseMenu}
            placement="right"
          >
            <MobileMenu />
          </MenuDrawer>
        </MobileWrapper>
      </HeaderContainer>
    </Wrapper>
  );
};

export default Header;

const StyledMobileWrapper = styled(Container)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    a,
    p {
      font-size: 30px;
    }
  }
`;

const Wrapper = styled.div<{ theme: Theme }>`
  width: 100%;
  padding: 20px 0 20px 0;
  position: fixed;
  z-index: 10;
  background-color: ${(props) => props.theme.background || colors.white};
  top: 0;

  a,
  p {
    color: ${(props) => props.theme.text};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 25px 0 15px 0;
  }
`;

const MenuItem = styled.p<{ margin?: string }>`
  margin: 0;
  margin: ${(props) => props.margin ?? "auto 0"};
  cursor: pointer;
  user-select: none;
`;

const HeaderContainer = styled(Container)`
  width: auto;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0 20px;
  }
`;

const DesktopLinksContainer = styled(Container)`
  margin: 0;
  width: auto;
  justify-content: space-between;

  .link {
    padding-left: 40px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileWrapper = styled.div<{ theme: Theme }>`
  a,
  p {
    color: ${colors.white} !important;
  }

  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

const Img = styled.img`
  user-select: none;
  cursor: pointer;
`;
