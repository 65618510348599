import React from "react";
import styled from "styled-components";

import { assets, BUTTON_HEIGHT, colors, colorsRGB } from "@util/constants";
import { Maybe } from "@graphql-types";

const StyledSelect = styled.select<{ isSelected?: boolean }>`
  background-image: url(${assets.downArrowBlue});
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: ${BUTTON_HEIGHT}px;
  padding: 0 20px;
  border: 1px solid
    ${(props) =>
      props.isSelected ? colors.flightBlue : colorsRGB.flightBlue(0.4)};
  color: ${(props) =>
    props.isSelected ? colors.flightBlue : colorsRGB.flightBlue(0.6)};
  font-family: "NeueMontreal-Regular";

  &:focus {
    outline: 0;
    border: 1px solid ${colors.flightBlue};
  }
`;

interface Props {
  placeholder: string;
  options: Maybe<Maybe<string>[]> | undefined;
  name: string;
  value?: string;
  onChange: (arg?: any) => void;
}

const Select = (props: Props) => {
  return (
    <StyledSelect
      name={props.name}
      id={props.name}
      onChange={props.onChange}
      defaultValue="placeholder"
      isSelected={props.value !== ""}
    >
      <option value="placeholder" disabled>
        {props.placeholder}
      </option>
      {props.options?.map((opt) => {
        if (opt == null) {
          return null;
        }
        const value = opt.replace(/\s+/g, "-").toLowerCase();

        return (
          <option key={value} value={value}>
            {opt}
          </option>
        );
      })}
    </StyledSelect>
  );
};

export default Select;
