import React, { ReactNode, useEffect } from "react";
import { Swiper } from "swiper/react";
import SwiperCore, { Pagination, Controller, Navigation } from "swiper";
// update swiper styling here
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import { useHasMounted } from "@util/hooks";
interface Props extends Swiper {
  children: ReactNode;
}

export default function Carousel(props: Props) {
  const mounted = useHasMounted();
  useEffect(() => {
    SwiperCore.use([Controller, Pagination, Navigation]);
  }, [mounted]);
  // elements such as dots or next/prev are added through the modules property
  return (
    <Swiper {...props} style={{ width: "100%", height: "100%" }}>
      {/* in order for the children to show you must wrap every child with "SwiperSlide" component */}
      {props.children}
    </Swiper>
  );
}
