import React, { useState } from "react";

import { SanityCard, SanityCardCarousel } from "@graphql-types";
import BlockContent from "@sanity/block-content-to-react";
import { assets, colors, TABLET_BREAKPOINT } from "@util/constants";
import { Container, DesktopContainer, TabletContainer } from "@util/standard";
import { Color } from "@util/types";
import styled from "styled-components";

import { SwiperSlide } from "swiper/react";
import { Swiper } from "swiper";
import SwiperCarousel from "./swiperCarousel";

interface Props {
  data: SanityCardCarousel;
}

function CardCarousel({ data }: Props) {
  if (data == null) return null;
  const [controller, setController] = useState<Swiper>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { title, cards } = data;

  const handleSlideChange = () => {
    if (controller?.realIndex === 0) {
      setCurrentSlide(controller?.realIndex);
    }

    if (controller?.realIndex) {
      setCurrentSlide(controller?.realIndex);
    }
  };

  const handleClick = (isNext?: boolean) => {
    if (controller) {
      isNext ? controller.slideNext() : controller.slidePrev();
    }
  };
  const cardsLength = cards?.length;

  return (
    <Container
      flexDirection="column"
      width="calc(100% + 40px)"
      height="100%"
      margin="0 0 0 -20px"
      tabletWidth="100%"
      tabletMargin="0"
    >
      {/* {title && (
        <TitleWrapper>
          <h3>{title}</h3>
        </TitleWrapper>
      )} */}
      <CarouselWrapper>
        <DesktopContainer width="100%">
          {cards && (
            <SwiperCarousel
              slidesPerView={3.1}
              spaceBetween={50}
              centeredSlides
              loop
              controller={controller}
              onSwiper={(swiper) => setController(swiper)}
              onSlideChange={handleSlideChange}
            >
              {cards.map((card, index) => {
                if (card == null) return null;

                const isActive = currentSlide === index;
                return (
                  <SwiperSlide
                    key={card._key}
                    style={{
                      transition: "all 0.3s",
                      // transform: isActive ? "scale(1.05)" : "scale(1)",
                      opacity: isActive ? 1 : 0.6
                    }}
                  >
                    <Card data={card} count={cardsLength} index={index} />
                  </SwiperSlide>
                );
              })}
            </SwiperCarousel>
          )}
        </DesktopContainer>
        <TabletContainer margin="0" width="100%">
          <Container width="100%" margin="0" flexDirection="column">
            {cards &&
              cards.map((card, index) => {
                if (card == null) return null;

                return <Card data={card} key={card._key} index={index} count={cardsLength} />;
              })}
          </Container>
        </TabletContainer>
        <DesktopContainer>
          <img src={assets.leftArrowBlue} onClick={() => handleClick()} />
          <img src={assets.rightArrowBlue} className="right" onClick={() => handleClick(true)} />
        </DesktopContainer>
      </CarouselWrapper>
    </Container>
  );
}

export default CardCarousel;

interface CardProps {
  data: SanityCard;
  index: number;
  count: number;
}

const Card = ({ data, index, count }: CardProps) => {
  const { title, label, cardColor, textContent } = data;
  return (
    <CardWrapper backgroundColor={cardColor as Color}>
      <div className="title-block">
        <p className="pagination">
          <span>{index + 1}</span>
          <span>/</span>
          <span>{count}</span>
        </p>
        <h4 className="card-label">{label}</h4>
        <h4 className="card-title">{title}</h4>
      </div>
      <BlockContent blocks={textContent?._rawColumnContent} />
    </CardWrapper>
  );
};

const CardWrapper = styled(Container)`
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  padding: 40px 11%;
  min-height: 420px;
  height: auto;
  border-radius: 20px;
  h4 {
    font-size: 36px;
    line-height: 43px;
    margin: 0 auto 50px auto;
  }
  p {
    margin: 0;
  }
  h4,
  p,
  strong {
    color: ${colors.white};
  }
  p,
  strong {
    font-size: 16px;
    line-height: 20px;
  }

  .card-label {
    margin: 0 auto 15px auto;
    font-size: 24px;
    line-height: 28px;
  }

  .card-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
  }

  .pagination {
    p {
      margin: 0 auto 20px auto;
    }
  }

  .title-block {
    p {
      margin: 0 auto 15px auto;
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 0;
    min-height: 0px;
    padding: 50px 0;
    h4,
    p,
    strong {
      width: 76%;
      margin: auto;
    }
    h4 {
      font-size: 24px;
      line-height: 28px;
      margin: 0 auto 35px auto;
    }
    p,
    strong {
      font-size: 15px;
      line-height: 18px;
    }

    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const CarouselWrapper = styled(Container)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
  img {
    filter: brightness(0);
    width: 20px;
    position: absolute;
    top: 50%;
    left: 35px;
    z-index: 2;
    cursor: pointer;
  }
  .right {
    left: unset;
    right: 35px;
  }
`;

const TitleWrapper = styled.div`
  h3 {
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 43px;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h3 {
      font-size: 20px;
      line-height: 24px;
      width: 80%;
      margin: 0 auto 20px auto;
    }
  }
`;
