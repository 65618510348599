import { SanityImageTextColumn } from "@graphql-types";
import React from "react";

import { BlocksContent, Image } from "@sub";

import {
  ImageContainer,
  ImageTextBlockContainer,
  SecondColumnContainer,
} from "../../../templates/template.styles";

interface Props {
  data: SanityImageTextColumn;
}

const ImageTextColumn = ({ data }: Props) => {
  if (!data) return null;
  return (
    <ImageTextBlockContainer
      key={data?._key}
      // @ts-ignore
      reverseDirection={data.reverse}
    >
      <ImageContainer>
        <Image
          objectFit="contain"
          // @ts-ignore
          fluid={data?.firstColumn?.asset?.fluid as FluidObject}
        />
      </ImageContainer>
      <SecondColumnContainer
        // @ts-ignore
        reverse={data.reverse}
      >
        <BlocksContent
          // @ts-ignore
          blocks={data?.secondColumn._rawColumnContent}
        />
      </SecondColumnContainer>
    </ImageTextBlockContainer>
  );
};

export default ImageTextColumn;
