import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { ButtonTheme, Dimensions } from "@util/types";
import {
  buttonStyle,
  fontSizes,
  MOBILE_BREAKPOINT,
  ANIMATION_TRANSITION,
} from "@util/constants";
import Loading from "./loading";
import { Container, GiveMeSomeSpace, P, A } from "@util/standard";

const StyledInput = styled.input<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  margin?: string;
  width?: string;
}>`
  font-size: ${fontSizes.p.default}px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${({ margin }) => margin && `margin: ${margin};`}
  padding: 10px 25px;
  ${({ width }) => width && `width: ${width};`}
  height: ${(props) => props.dimensions?.height ?? `auto`};
  color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.bg};
  transition: background-color ${ANIMATION_TRANSITION}s linear;

  &:hover {
    outline: 0;
    border-color: ${(props) => props.theme.hoverBorder};
    color: ${(props) => props.theme.hoverText};
    background-color: ${(props) => props.theme.hoverBg};
  }
`;

const StyledButton = styled.button<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  disableHover?: boolean;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
  fontSize?: number;
}>`
  font-size: ${(props) => props.fontSize ?? 20}px !important;
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${({ margin }) => margin && `margin: ${margin};`}
  padding: ${(props) => (props.padding ? props.padding : `10px 25px`)};
  ${({ width }) => width && `width: ${width};`}
  height: ${(props) => props.dimensions?.height ?? `auto`};
  color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => props.theme.border};
  background-color: ${(props) => props.theme.bg};
  transition: background-color ${ANIMATION_TRANSITION}s linear;

  &:focus {
    outline: 0;
  }
  ${({ disableHover, theme }) =>
    !disableHover &&
    `&:hover {
      outline: 0;
      border-color: ${theme.hoverBorder};
      color: ${theme.hoverText};
      background-color: ${theme.hoverBg};
  }`}

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ mobileDimensions }) => mobileDimensions && `width:${mobileDimensions}`}
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    font-size: ${fontSizes.p.mobile}px;
    line-height: ${fontSizes.p.mobile * 1.5}px;
  }

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const LoadingContainer = styled(Container)`
  flex: 1;
  justify-content: center;
`;

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  className?: string;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  minHeight?: number;
  text: string;
  margin?: string;
  padding?: string;
  disableHoverEffect?: boolean;
  mobileMargin?: string;
  onClick?: (args?: any) => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  linkTo?: string;
  linkToNewWindow?: string;
  width?: string;
  isInput?: boolean;
}

const Button = ({
  theme = "base",
  disabled,
  dimensions,
  width,
  mobileDimensions,
  text,
  margin,
  disableHoverEffect,
  onClick,
  mobileMargin,
  loading,
  minHeight,
  padding,
  type,
  linkTo,
  linkToNewWindow,
  isInput,
}: Props) => {
  const Loader = () => (
    <LoadingContainer>
      <>
        <Loading />
        <GiveMeSomeSpace space={3} />
        <P noMargin color="white">
          Loading..
        </P>
      </>
    </LoadingContainer>
  );

  const handleOnClick = () => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  const RenderedButton = ({
    externalLink,
  }: {
    externalLink?: string | undefined;
  }) => (
    <StyledButton
      theme={buttonStyle[theme]}
      dimensions={dimensions}
      width={width}
      disabled={disabled}
      onClick={handleOnClick}
      margin={margin}
      padding={padding}
      mobileDimensions={mobileDimensions}
      disableHover={loading || disableHoverEffect}
      mobileMargin={mobileMargin}
      minHeight={minHeight}
      type={type}
    >
      {loading ? (
        <Loader />
      ) : externalLink ? (
        <A
          href={externalLink}
          hoverColor="white"
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </A>
      ) : (
        text
      )}
    </StyledButton>
  );

  if (isInput) {
    return (
      <StyledInput
        disabled={disabled}
        type={type}
        defaultValue={text}
        margin={margin}
        theme={buttonStyle[theme]}
        width={width}
      />
    );
  }

  if (linkTo) {
    return (
      <GatsbyLink to={linkTo}>
        <RenderedButton />
      </GatsbyLink>
    );
  }

  return <RenderedButton externalLink={linkToNewWindow} />;
};

export default Button;
